Footer{
    background-color: var(--ado_dark);
    color: var(--ado_text);
    font-size: 1.5vh;
}

p {
    display: block;
    margin-block-start: 0.25em;
    margin-block-end: 0.25em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.social-media a {
    text-decoration: none;
    color: var(--ado_text);
}

.social-media a:hover {
    color: var(--ado_light); /* Facebook hover color */
}