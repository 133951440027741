.header{
    text-align: center;
    vertical-align: middle;
    background-color: var(--ado_dark);
    box-sizing: border-box;
    padding: 1vh;
}

.header_logo{
    width: auto;
    height: 10vh;
}