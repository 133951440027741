:root {
  --ado_dark: #051923;
  --ado_dark_blue: #003554;
  --ado_blue: #006494;
  --ado_light_blue: #0582CA;
  --ado_light: #00A6FB;
  --ado_text: #ffffff;
  --ado_green_accent: #019127;
  --ado_red_accent: #BA1A1A;
}

* {
  box-sizing: border-box;
}

.overlay-image {
  opacity: 0.75;
  /* Adjust the opacity as needed */
}

body {
  font-family: 'Tw Cen MT Std';
  font-size: 2vh;
  margin: 0;
  background: #e6e6e6;
}


nav {
  background-color: #003554;
}

.nav {
  box-sizing: border-box;
  background-color: var(--ado_dark_blue);
  width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  max-width: 1000px;
  width: auto;
  margin: auto;
  justify-content: center;
  display: flex;
  font-size: 2.5vh;
}

.navlink {
  text-align: center;
  display: flex;
  height: 100%;
  width: 100%;
  padding: 1vh 1vw;
  color: #000000;
  text-decoration: none;
  flex-direction: column;
  justify-content: center;
}

.active {
  background-color: #f2f2f2;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background-color: var(--ado_light_blue);
}

li a:active {
  background-color: #f2f2f2;
}

li {
  float: left;
  /* Set a default float for list items */
}

.nav_link--active {
  background-color: #f2f2f2;
}

.content {
  padding: 10px;
  box-sizing: border-box;
  max-width: 1000px;
  margin: auto;
  background-color: var(--ado_text);
  box-sizing: border-box;
  text-align: center;
  min-height: 73vh;
}

.disclaimer {
  font-size: 1.5vh;
}

.p_head {
  font-size: 4vh;
}

.p_subhead {
  font-size: 3vh;
}

p {
  font-size: 2vh;
}

.stat_head {
  padding: 1vh;
  font-size: 2vh;
}

.stat {
  padding: 1vh;
  font-size: 5vh;
  color: #ffffff;
  background-image: url('../images/stat_roundel.png');
  /* Adjust the path as needed */
  background-size: cover;
  background-repeat: no-repeat;
  text-align: left;
}

.dropdown {
  display: flex;
  align-items: center;
  padding: 0.5vh 0;
  border-radius: 0;
  background-color: var(--ado_blue);
  color: var(--ado_text);
}

.dropdown:hover {
  background-color: var(--ado_light_blue);
}

.dropdown:active {
  background-color: var(--ado_light);
}

.link a {
  text-decoration: none;
  color: #000000;
}

.link a:hover {
  color: var(--ado_light);
  /* Facebook hover color */
}

.dropdown-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}